//Copyright by Chaafo pvt ltd


import React, { useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CouponCard from './common/CouponCard';
import BankOffers from './common/BankOffers';
import GlobalContext_ from '../GlobalContext';

import { Helmet } from 'react-helmet';
import seoData from '../seo.json';

function Offers() {

	//Using global context of vouchers
	const { voucher, restaurantinfo } = useContext(GlobalContext_);

	//Function to scroll the tab to top
	const scrollTotop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const metaData = seoData.data[0].pages.find(page => page.basic_settings.page_name == 'offers')

	const pageTitle = metaData.basic_settings?.tittle
	const basicId = metaData.basic_settings?.basic_id
	const mobileView = metaData.basic_settings?.mobile_view
	const metaDescription = metaData.basic_settings?.meta_desc
	const canonical = metaData.basic_settings?.canonical_tag

	const keywords = metaData.keyword_settings.map((key) => key.keyword)

	const ogTitle = metaData.og_settings?.og_title
	const ogDescription = metaData.og_settings?.og_desc
	const ogSitename = metaData.og_settings?.og_sitename
	const ogImage = metaData.og_settings?.og_image
	const ogImageType = metaData.og_settings?.og_image_type
	const ogImageWidth = metaData.og_settings?.og_image_width
	const ogImageHeight = metaData.og_settings?.og_image_height
	const ogLocale = metaData.og_settings?.og_locale
	const ogURL = metaData.og_settings?.og_url
	const ogType = metaData.og_settings?.og_type
	const ogId = metaData.og_settings?.og_id
	const ogAudio = metaData.og_settings?.og_audio
	const ogProfile = metaData.og_settings?.og_profile
	const ogVideo = metaData.og_settings?.og_video
	const ogVideoSecureURL = metaData.og_settings?.og_video_secure_url
	const ogVideoType = metaData.og_settings?.og_video_type
	const ogVideoWidth = metaData.og_settings?.og_video_width
	const ogVideoHeight = metaData.og_settings?.og_video_height
	const ogDeterminer = metaData.og_settings?.og_determiner

	const fbAppId = metaData.og_settings?.fb_app_id
	const fbAuthor = metaData.og_settings?.fb_og_article_author
	const fbPublishTime = metaData.og_settings?.fb_og_article_published_time
	const fbModifyTime = metaData.og_settings?.fb_og_article_modified_time

	const twitterCard = metaData.og_settings?.twitter_card
	const twitterCreator = metaData.og_settings?.twitter_creator
	const twitterImage = metaData.og_settings?.twitter_image
	const twitterURL = metaData.og_settings?.twitter_url
	const twitterSite = metaData.og_settings?.twitter_site
	const twitterDescription = metaData.og_settings?.twitter_desc
	const twitterTitle = metaData.og_settings?.twitter_title

	return (
		<>
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<title>{pageTitle}</title>
				<link rel="icon" href="/favicon.svg" />
				<link rel="canonical" href={canonical} />
				<meta name="description" content={metaDescription}></meta>
				<meta name="keywords" content={keywords}></meta>
				<meta name="basic-id" content={basicId}></meta>
				<meta name="mobile-view" content={mobileView}></meta>

				{/* og meta tags */}
				<meta property="og:id" content={ogId}></meta>
				<meta property="og:site_name" content={ogSitename}></meta>
				<meta property="og:title" content={ogTitle}></meta>
				<meta property="og:description" content={ogDescription}></meta>
				<meta property="og:url" content={ogURL}></meta>
				<meta property="og:image" content={ogImage}></meta>
				<meta property="og:image:type" content={ogImageType} />
				<meta property="og:image:width" content={ogImageWidth} />
				<meta property="og:image:height" content={ogImageHeight} />
				<meta property="og:type" content={ogType} />
				<meta property="fb:app_id" content={fbAppId} />
				<meta property="og:profile" content={ogProfile} />
				<meta property="og:locale" content={ogLocale} />
				<meta property="og:audio" content={ogAudio} />
				<meta property="og:video:secure:url" content={ogVideoSecureURL} />
				<meta property="og:video:type" content={ogVideoType} />
				<meta property="og:video:width" content={ogVideoWidth} />
				<meta property="og:video:height" content={ogVideoHeight} />
				<meta property="og:determiner" content={ogDeterminer} />
				<meta property="og:video" content={ogVideo} />

				{/* Twitter meta tags */}
				<meta name="twitter:card" content={twitterCard} />
				<meta name="twitter:site" content={twitterSite} />
				<meta name="twitter:title" content={twitterTitle} />
				<meta name="twitter:description" content={twitterDescription} />
				<meta name="twitter:image" content={twitterImage} />
				<meta name="twitter:url" content={twitterURL} />
				<meta name="twitter:creator" content={twitterCreator} />

				{/* Facebook Open Graph Specific Meta Tags (if needed) */}
				<meta property="og:app_id" content={fbAppId} />
				<meta property="og:article:author" content={fbAuthor} />
				<meta property="og:article:published_time" content={fbPublishTime} />
				<meta property="og:article:modified_time" content={fbModifyTime} />

			</Helmet>
			<PageTitle
				title="Offers for you"
				subTitle={voucher.offer?.map(offer => <div>{offer}</div>)}
			/>
			<section className="section pt-5 pb-5">
				<Container>
					<Row>
						<Col md={12}>
							<h4 className="font-weight-bold mt-0 mb-3">Available Coupons</h4>
						</Col>
						<Col md={8}>

							{(voucher.voucher?.map(Voucher =>
								<CouponCard
									copyBtnText='COPY CODE'
									couponCode={Voucher.voucher_name}
									title={Voucher.voucher_title}
									subTitle={Voucher.voucher_description}
									discount={Voucher.Discount}
									noBorder={false}
									onClick={scrollTotop}
								/>
							))}
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default Offers;