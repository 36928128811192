//Copyright by Chaafo pvt ltd


import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';

import { Helmet } from 'react-helmet';
import seoData from '../seo.json';

function Register() {

    const { MK, restaurantinfo } = useContext(GlobalContext_);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cpasswordShown, setcPasswordShown] = useState(false);

    //Function to enable show or hide password    
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const ctogglePassword = () => {
        setcPasswordShown(!cpasswordShown)
    };

    //initializing initial values to set them as initial state of formvalues
    const initialValues = { first_name: "", last_name: "", contact_phone: "", email_address: "", password: "", cpassword: "" };

    // formValues are sent as parameters in api
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const history = useHistory();

    const metaData = seoData.data[0].pages.find(page => page.basic_settings.page_name == 'register')

    const pageTitle = metaData.basic_settings?.tittle
	const basicId = metaData.basic_settings?.basic_id
    const mobileView = metaData.basic_settings?.mobile_view
    const metaDescription = metaData.basic_settings?.meta_desc
    const canonical = metaData.basic_settings?.canonical_tag

    const keywords = metaData.keyword_settings.map((key) => key.keyword)

    const ogTitle = metaData.og_settings?.og_title
    const ogDescription = metaData.og_settings?.og_desc
    const ogSitename = metaData.og_settings?.og_sitename
    const ogImage = metaData.og_settings?.og_image
    const ogImageType = metaData.og_settings?.og_image_type
    const ogImageWidth = metaData.og_settings?.og_image_width
    const ogImageHeight = metaData.og_settings?.og_image_height
    const ogLocale = metaData.og_settings?.og_locale
    const ogURL = metaData.og_settings?.og_url
    const ogType = metaData.og_settings?.og_type
    const ogId = metaData.og_settings?.og_id
    const ogAudio = metaData.og_settings?.og_audio
	const ogProfile = metaData.og_settings?.og_profile
    const ogVideo = metaData.og_settings?.og_video
    const ogVideoSecureURL = metaData.og_settings?.og_video_secure_url
    const ogVideoType = metaData.og_settings?.og_video_type
    const ogVideoWidth = metaData.og_settings?.og_video_width
    const ogVideoHeight = metaData.og_settings?.og_video_height
    const ogDeterminer = metaData.og_settings?.og_determiner

    const fbAppId = metaData.og_settings?.fb_app_id
    const fbAuthor = metaData.og_settings?.fb_og_article_author
    const fbPublishTime = metaData.og_settings?.fb_og_article_published_time
    const fbModifyTime = metaData.og_settings?.fb_og_article_modified_time

    const twitterCard = metaData.og_settings?.twitter_card
    const twitterCreator = metaData.og_settings?.twitter_creator
    const twitterImage = metaData.og_settings?.twitter_image
    const twitterURL = metaData.og_settings?.twitter_url
    const twitterSite = metaData.og_settings?.twitter_site
    const twitterDescription = metaData.og_settings?.twitter_desc
    const twitterTitle = metaData.og_settings?.twitter_title

    async function signUp() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("first_name", formValues.first_name);
        formdata.append("last_name", formValues.last_name);
        formdata.append("contact_phone", formValues.contact_phone);
        formdata.append("password", formValues.password);
        formdata.append("cpassword", formValues.cpassword);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        //for registering customer
        let result = await fetch(urls.Register + "?next_step=home_page&next_step2=firsttime&merchant_keys=" + MK + "&email_address=" + formValues.email_address, requestOptions);

        result = await result.json();

        sessionStorage.setItem("keycheck", (result.details.token));
        if (result.code === 1) {

            //if user is registered successfully, his first name, contact phone and email address will be fetched and stored from form values
            localStorage.setItem("first_name", formValues.first_name);
            localStorage.setItem("contact_phone", formValues.contact_phone);
            localStorage.setItem("email_address", formValues.email_address);
            history.push("/otp");

        }
        else {
            history.push("/Register");
            toast.error(result.msg, {
                position: "top-center",
                autoClose: 600
            });
        }
    };

    //handle change function set the first name and other values in formvalues
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //handleSubmit is called on pressing submit button
    const handleSubmit = (e) => {
        e.preventDefault();

        //validate function is called to see whether there is any error in the values input by the user
        setFormErrors(validate(formValues));
        console.log(formErrors)
        setIsSubmit(true);

    };

    useEffect(() => {

        //if there are no form errors, user will be signed up successfully
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            signUp();
        }
    }, [formErrors]);

    toast.configure();

    //validate function is to check for errors
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.first_name) {
            errors.first_name = "*First name is required!";
        }
        if (!values.last_name) {
            errors.last_name = "*Last name is required!";
        }
        if (!values.contact_phone) {
            errors.contact_phone = "*Mobile no. is required!";
        } else if (values.contact_phone.length != 10) {
            errors.contact_phone = "Enter valid Mobile No.";
        } else if (values.contact_phone === NaN) {
            errors.contact_phone = "Enter valid Mobile No.";
        }
        if (!values.email_address) {
            errors.email_address = "*Email is required!";
        } else if (!regex.test(values.email_address)) {
            errors.email_address = "This is not a valid email format!";
        }
        if (!values.password) {
            errors.password = "*Password is required";
        } else if (values.password.length < 4) {
            errors.password = "Password must be more than 4 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }
        if (values.cpassword != values.password) {
            errors.cpassword = "Password and Confirm password does not match";
        }
        return errors;
    };
    return (
        <>
            <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<title>{pageTitle}</title>
				<link rel="icon" href="/favicon.svg" />
				<link rel="canonical" href={canonical} />
				<meta name="description" content={metaDescription}></meta>
				<meta name="keywords" content={keywords}></meta>
				<meta name="basic-id" content={basicId}></meta>
				<meta name="mobile-view" content={mobileView}></meta>

				{/* og meta tags */}
				<meta property="og:id" content={ogId}></meta>
				<meta property="og:site_name" content={ogSitename}></meta>
				<meta property="og:title" content={ogTitle}></meta>
				<meta property="og:description" content={ogDescription}></meta>
				<meta property="og:url" content={ogURL}></meta>
				<meta property="og:image" content={ogImage}></meta>
				<meta property="og:image:type" content={ogImageType} />
				<meta property="og:image:width" content={ogImageWidth} />
				<meta property="og:image:height" content={ogImageHeight} />
				<meta property="og:type" content={ogType} />
				<meta property="fb:app_id" content={fbAppId} />
				<meta property="og:profile" content={ogProfile} />
				<meta property="og:locale" content={ogLocale} />
				<meta property="og:audio" content={ogAudio} />
				<meta property="og:video:secure:url" content={ogVideoSecureURL} />
				<meta property="og:video:type" content={ogVideoType} />
				<meta property="og:video:width" content={ogVideoWidth} />
				<meta property="og:video:height" content={ogVideoHeight} />
				<meta property="og:determiner" content={ogDeterminer} />
				<meta property="og:video" content={ogVideo} />

				{/* Twitter meta tags */}
				<meta name="twitter:card" content={twitterCard} />
				<meta name="twitter:site" content={twitterSite} />
				<meta name="twitter:title" content={twitterTitle} />
				<meta name="twitter:description" content={twitterDescription} />
				<meta name="twitter:image" content={twitterImage} />
				<meta name="twitter:url" content={twitterURL} />
				<meta name="twitter:creator" content={twitterCreator} />

				{/* Facebook Open Graph Specific Meta Tags (if needed) */}
				<meta property="og:app_id" content={fbAppId} />
				<meta property="og:article:author" content={fbAuthor} />
				<meta property="og:article:published_time" content={fbPublishTime} />
				<meta property="og:article:modified_time" content={fbModifyTime} />
            </Helmet>
            <Container fluid className='bg-white'>
                <Row>
                    <Col md={4} lg={6}>
                        <div>

                            <img className="login12" alt={restaurantinfo.merchant_name} src={restaurantinfo.merchant_image} ></img>

                        </div>
                    </Col>
                    <Col md={8} lg={6}>
                        <div className="login d-flex align-items-center py-5">

                            <Container>
                                <Row>
                                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                        <h3 className="login-heading mb-4">New Buddy!</h3>

                                        <form >
                                            <div className="form-label-group mb-4">
                                                <Form.Control type="text" name="first_name" value={formValues.first_name} required autoComplete='off' onChange={handleChange} id="first_name" placeholder="First Name" />
                                                <Form.Label htmlFor="first_name">First Name</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.first_name}</p>
                                            <div className="form-label-group mb-4">
                                                <Form.Control type="text" name="last_name" value={formValues.last_name} required autoComplete='off' onChange={handleChange} id="last_name" placeholder="Last Name" />
                                                <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.last_name}</p>
                                            <div className="form-label-group mb-4">
                                                <Form.Control type="number" name="contact_phone" value={formValues.contact_phone} required autoComplete='off' onChange={handleChange} id="contact_phone" maxLength={10} placeholder="Mobile No." />
                                                <Form.Label htmlFor="contact_phone">Mobile No.</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.contact_phone}</p>
                                            <div className="form-label-group">
                                                <Form.Control type="email" name="email_address" value={formValues.email_address} required autoComplete='off' onChange={handleChange} id="email_address" placeholder="Email address" />
                                                <Form.Label htmlFor="email_address">Email address</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.email_address}</p>
                                            <div className="form-label-group">
                                                <Form.Control type={passwordShown ? "text" : "password"} name="password" value={formValues.password} required autoComplete='off' onChange={handleChange} id="password" placeholder="Password" />
                                                <Form.Label htmlFor="password">Password
                                                    {passwordShown ?
                                                        <div className='cursorPoint'>
                                                            <i className="icofont-eye-blocked float-right" onClick={togglePassword}></i>
                                                        </div>
                                                        :
                                                        <div className='cursorPoint'>
                                                            <i className="icofont-eye float-right" onClick={togglePassword}></i>
                                                        </div>}
                                                </Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.password}</p>
                                            <div className="form-label-group">
                                                <Form.Control type={cpasswordShown ? "text" : "password"} name="cpassword" value={formValues.cpassword} required autoComplete='off' onChange={handleChange} id="cpassword" placeholder="Confirm Password" />
                                                <Form.Label htmlFor="cpassword">Confirm Password
                                                    {cpasswordShown ?
                                                        <div className='cursorPoint'>
                                                            <i className="icofont-eye-blocked float-right" onClick={ctogglePassword}></i>
                                                            </div> 
                                                            : <div className='cursorPoint'><i className="icofont-eye float-right " onClick={ctogglePassword}></i></div>}</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.cpassword}</p>
                                            <button to='/otp' className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={handleSubmit} >Sign Up</button>
                                            <div className="text-center pt-3">
                                                Already have an account? <Link className="font-weight-bold" to="/login" >Sign In</Link>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>


    );
}

export default Register;