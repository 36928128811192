//Copyright by Chaafo pvt ltd
//TopSearch contains header, background image of merchant, categories carousel, banner, logo and locate me bar.

import React, { useContext, useState, useEffect, usePos } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Form, Image } from 'react-bootstrap';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from './ProductBox';
import CategoriesCarousel from '../common/CategoriesCarousel';
import GlobalContext_ from '../../GlobalContext';
import HeroSection from '../../heroSection.png'

import { Helmet } from 'react-helmet';
// import { SiteColors } from '../../constants/colorTheme';

function TopSearch() {
    const { banner, restaurantinfo, background, locate: loc, MK, } = useContext(GlobalContext_);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [map, setMap] = useState([]);

    //function to fetch user's current location
    const fetchMap = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyB4mMpI8D12QHkyehxumzNsd2SinFY8oZk`)
                .then(response => response.json())
                .then(result => { setMap(result.results[0].formatted_address) });
            loc = map;
            // Do something with the response
            setIsLoaded(true);
        });
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section style={{
                padding: '2rem 10rem',
                height: '80%',
                background: 'linear-gradient(0deg, rgba(247,244,244,1) 3%, rgba(136,0,0,0.1923144257703081) 55%, rgba(244,244,244,1) 97%)',
            }} className=" align-items-center justify-content-center d-flex" >
                {/* <Container > */}
                {/* <Row className="d-flex align-items-center justify-content-center"> */}
                    <div className='card-slide-in-left'>
                        <div className="" >
                            <h1 className="mb-2 font-weight-bold" style={{ color: 'orange' }} > Order Directly from<span className="font-weight-bold" style={{ fontWeight: 'bold', fontSize: '3rem', color: '#cb6e1e' }}> <br />{restaurantinfo.merchant_name}</span></h1><br></br>
                            <h2 className="mb-2 font-weight-normal" style={{ color: 'orange' }}> - Fresh, fast and delivered right to your door !</h2>
                            <Form className="form-noborder homepage-search-form ">
                                <Form.Group className='col-lg-12 col-md-12 col-sm-12 p-0 m-0'>
                                    <Form.Control type="text" placeholder="Enter your delivery location" value={loc} size='lg' />
                                    <Link className="locate-me" onClick={fetchMap}><Icofont icon='ui-pointer' /> Locate Me</Link>
                                </Form.Group>
                            </Form>
                        </div>
                        {/* <CategoriesCarousel /> */}
                    </div>
                    <div className='card-slide-in-right'>
                        <img src={HeroSection} style={{height: '500px'}}/>
                    </div>
                {/* </Row> */}
                {/* </Container> */}
            </section>
        </>
    );
}

const options2 = {
    responsive: {
        0: {
            items: 1,
        },
        764: {
            items: 1,
        },
        765: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    },
    lazyLoad: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 800,
    dots: false,
    autoplayTimeout: 2000,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    autoplayHoverPause: true,
}

export default TopSearch;