//Copyright by Chaafo pvt ltd

import React from 'react';
import PropTypes from 'prop-types';
import { SiteColors, CardColors } from '../../constants/colorTheme';

function PageTitle(props) {

	return (
		<section className="breadcrumb-osahan pt-2 pb-2 position-relative text-center" style={{background: 'black'}}>
			<h1 className="text-white">{props.title}</h1>
			<h6 className="text-white-50" style={{ lineHeight: 1.7 }}>{props.subTitle}</h6>
		</section>
	);
}

PageTitle.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string
};
PageTitle.defaultProps = {
	subTitle: ''
}

export default PageTitle;